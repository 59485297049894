import { get, post, put, patch, del } from 'services/request.service';
import { getEnv } from 'services/apiEnv.service';
import { TAPIArgs } from 'api/api.types';
import { ALRequestSubmissionPayload, JustificationNote } from 'components/WizardTypes/Wizard.types';
import {
  DocumentationTypes,
  EditJustificationNotesPayload,
  Employee,
  GetJustificationNote
} from 'components/CommonTypes/Common.types';
import { AxiosResponse } from 'axios';
import {
  AddFDRBuildingPayload,
  AddFDRequestPayload,
  BuildingData,
  GetBuilding,
  RequestTimelineData,
  UpdateFDRBuildingPayload,
  UpdateRequestTimeline
} from './request.types';

const BASE_URL = `${getEnv('IT_BASE_URL')}/request-management`;
const DECISIONS_BASE_URL = `${getEnv('DECISIONS_URL')}`;
export const getRequest = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/requests/${id}/detail` })).data;
};

export const getRequests = async ({ params, id }: TAPIArgs = {}, pageNumber = 1) => {
  const response = await get({
    url: `${BASE_URL}/view-project-lists/${id}`,
    params: { ...params, pageNumber }
  });

  return {
    items: response.data,
    paginationMetadata: JSON.parse(response.headers.paginationmetadata),
    columnData: JSON.parse(response.headers.columndata),
    filterCount: JSON.parse(response.headers.filtercount)
  };
};

export const getCSVRequests = async params => {
  const response = await get({
    url: `${BASE_URL}/request-export`,
    params: { ...params }
  });

  return {
    items: response.data,
    columnData: JSON.parse(response.headers.columndata),
    filterCount: JSON.parse(response.headers.filtercount)
  };
};

export const getRequestViewColumns = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/current-user-views/${id}/columns` })).data;
};

export const getRequestFilterEnums = async () => {
  return (await get({ url: `${BASE_URL}/request-filter-enums` })).data;
};

export const getActiveRequestDepartments = async () => {
  return (await get({ url: `${BASE_URL}/departments` })).data;
};

export const getMultimediaDeliverables = async () => {
  return (await get({ url: `${BASE_URL}/branding-multimedia-deliverables` })).data;
};

export const getProjectPurposes = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/project-purposes?deptId=${id}` })).data;
};

export const getGenericEnums = async () => {
  return (await get({ url: `${BASE_URL}/generic-enums` })).data;
};

export const getBrandingEnums = async () => {
  return (await get({ url: `${BASE_URL}/branding-enums` })).data;
};

export const getBrandingAudienceTypes = async () => {
  return (await get({ url: `${BASE_URL}/branding-audience-types` })).data;
};

export const getBrandingShippingCriteria = async () => {
  return (await get({ url: `${BASE_URL}/branding-shipping-criteria` })).data;
};

export const getBrandingPrintRequestEnums = async () => {
  return (await get({ url: `${BASE_URL}/branding-print-enums` })).data;
};

export const getSignagePrintEnums = async () => {
  return (await get({ url: `${BASE_URL}/signage-print-enums` })).data;
};

export const getClinicalEvalEnums = async () => {
  return (await get({ url: `${BASE_URL}/getClinicalEvalEnums` })).data;
};

export const getBrandingDetails = async ({ requestId }: any) => {
  return (await get({ url: `${BASE_URL}/requests/${requestId}/branding-detail` })).data;
};

export const getBrandingDeliveryBuildings = async () => {
  return (await get({ url: `${BASE_URL}/branding-delivery-buildings` })).data;
};

export const getMapsizeBuildingRooms = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/mapwize-buildings/${id}/rooms` })).data;
};

export const getAttachmentExtensions = async () => {
  return (await get({ url: `${BASE_URL}/attachment-extensions` })).data;
};

export const getFacilityFormTypes = async () => {
  return (await get({ url: `${BASE_URL}/getFacilityFormTypes` })).data;
};

export const getFacilityJustifications = async () => {
  return (await get({ url: `${BASE_URL}/getFacilityJustifications` })).data;
};

export const getFacilityServiceRequirements = async () => {
  return (await get({ url: `${BASE_URL}/getFacilityServiceRequirements` })).data;
};

export const getScopeNoteTypes = async () => {
  return (await get({ url: `${BASE_URL}/getScopeNoteTypes` })).data;
};

export const getATSFormTypes = async () => {
  return (await get({ url: `${BASE_URL}/getATSFormTypes` })).data;
};

export const getDocumentationTypes = async (): Promise<DocumentationTypes[]> => {
  return (await get({ url: `${BASE_URL}/documentation-types` })).data;
};

export const getBusinessImpactEnums = async () => {
  return (await get({ url: `${BASE_URL}/business-impact-enums` })).data;
};

export const getATSRequestedCompletionTimes = async () => {
  return (await get({ url: `${BASE_URL}/getATSRequestedCompletionTimes` })).data;
};

export const addBrandingRequest = async (data: any) => {
  return (await post({ url: `${BASE_URL}/branding-requests`, data })).data;
};

export const submitFacilitiesRequest = async (data: any) => {
  return (await post({ url: `${BASE_URL}/submitFacilitiesRequest`, data })).data;
};

export const addATSRequest = async (data: any) => {
  return (await post({ url: `${BASE_URL}/ca-requests`, data })).data;
};

export const addALRequest = async (data: ALRequestSubmissionPayload) => {
  return (await post({ url: `${BASE_URL}/al-requests`, data })).data;
};

export const addFDRequest = async (data: AddFDRequestPayload) => {
  return (await post({ url: `${BASE_URL}/fd-requests`, data })).data;
};
export const submitAppliedLearningRequest = async (data: any) => {
  return (await post({ url: `${BASE_URL}/submitBrandingRequest`, data })).data;
};

export const addRequestAttachments = async ({ requestId, data }: any) => {
  return (
    await post({
      url: `${BASE_URL}/requests/${requestId}/attachments`,
      headers: { 'Content-Type': 'multipart/form-data' },
      data
    })
  ).data;
};

export const deleteRequestAttachments = async ({ requestId, data }: any) => {
  return (
    await del({
      url: `${BASE_URL}/requests/${requestId}/attachments`,
      data
    })
  ).data;
};

export const updateViewColumn = async ({ viewId, requestViewColumnId, data }: any) => {
  return (
    await put({
      url: `${BASE_URL}/current-user-views/${viewId}/columns/${requestViewColumnId}`,
      data
    })
  ).data;
};

export const resetViewColumns = async ({ id }: any) => {
  return (await put({ url: `${BASE_URL}/current-user-views/${id}/reset-view-columns` })).data;
};

export const updateShipping = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/requests/${requestId}/branding-shipping-detail`, data }))
    .data;
};

export const updateRequest = async ({ id, data, headers }: any) => {
  return (await patch({ url: `${BASE_URL}/requests/${id}`, data, headers })).data;
};

export const updateBrandingDetails = async ({ requestId, data, headers }: any) => {
  return (await put({ url: `${BASE_URL}/requests/${requestId}/branding-detail`, data, headers }))
    .data;
};

export const updateBrandingSignagePrintDetail = async ({
  requestId,
  brandingSignageDetailId,
  data
}: any) => {
  return (
    await put({
      url: `${BASE_URL}/requests/${requestId}/branding-signage-details/${brandingSignageDetailId}`,
      data
    })
  ).data;
};

export const addBrandingSignagePrintDetail = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/requests/${requestId}/branding-signage-details`, data }))
    .data;
};

export const deleteBrandingSignagePrintDetail = async ({
  requestId,
  brandingSignageDetailId,
  data
}: any) => {
  return (
    await del({
      url: `${BASE_URL}/requests/${requestId}/branding-signage-details/${brandingSignageDetailId}`,
      data
    })
  ).data;
};

export const updateBrandingPrintDetail = async ({
  requestId,
  brandingPrintDetailId,
  data
}: any) => {
  return (
    await put({
      url: `${BASE_URL}/requests/${requestId}/branding-print-details/${brandingPrintDetailId}`,
      data
    })
  ).data;
};

export const addBrandingPrintDetail = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/requests/${requestId}/branding-print-details`, data }))
    .data;
};

export const deleteBrandingPrintDetail = async ({
  requestId,
  brandingPrintDetailId,
  data
}: any) => {
  return (
    await del({
      url: `${BASE_URL}/requests/${requestId}/branding-print-details/${brandingPrintDetailId}`,
      data
    })
  ).data;
};

export const updateRequestEvent = async ({ requestId, eventId, data }: any) => {
  return (await put({ url: `${BASE_URL}/requests/${requestId}/events/${eventId}`, data })).data;
};

export const deleteRequestEvent = async ({ requestId, eventId, data }: any) => {
  return (await del({ url: `${BASE_URL}/requests/${requestId}/events/${eventId}`, data })).data;
};

export const addRequestEvent = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/requests/${requestId}/events`, data })).data;
};

export const deleteRequestAttachment = async ({ requestId, attachmentId, data }: any) => {
  return (await del({ url: `${BASE_URL}/requests/${requestId}/attachments/${attachmentId}`, data }))
    .data;
};

export const updateProjectUpdateDetail = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/requests/${requestId}/project-update-detail`, data })).data;
};

export const addProjectUpdateDetail = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/requests/${requestId}/project-update-detail`, data }))
    .data;
};

export const deleteProjectUpdateDetail = async ({ requestId, data }: any) => {
  return (await del({ url: `${BASE_URL}/requests/${requestId}/project-update-detail`, data })).data;
};

export const updateRequestDetailNotes = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/requests/${requestId}/request-detail-notes`, data })).data;
};

export const updateJustificationNotes = async ({
  requestId,
  data
}: {
  requestId: number;
  data: EditJustificationNotesPayload;
}) => {
  return (await put({ url: `${BASE_URL}/requests/${requestId}/justification-notes`, data })).data;
};

export const getJustificationNotes = async ({
  requestId
}: any): Promise<GetJustificationNote[]> => {
  return (await get({ url: `${BASE_URL}/requests/${requestId}/justification-notes` })).data;
};

export const getRequestDetailsInfo = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/requests/${id}` })).data;
};

export const addRequestDetailNotes = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/requests/${requestId}/request-detail-notes`, data })).data;
};

export const getCharterDetails = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/requests/${id}/charter` })).data;
};

export const addCharterDetails = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/requests/${requestId}/charter`, data })).data;
};

export const updateCharterDetails = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/requests/${requestId}/charter`, data })).data;
};

export const getLearningCharterDetails = async ({ id }: TAPIArgs = {}) => {
  return (await get({ url: `${BASE_URL}/requests/${id}/learning-charter` })).data;
};

export const addLearningCharterDetails = async ({ requestId, data }: any) => {
  return (await post({ url: `${BASE_URL}/requests/${requestId}/learning-charter`, data })).data;
};

export const updateLearningCharterDetails = async ({ requestId, data }: any) => {
  return (await put({ url: `${BASE_URL}/requests/${requestId}/learning-charter`, data })).data;
};

export const getCharterEnums = async () => {
  return (await get({ url: `${BASE_URL}/charter-enums` })).data;
};

export const getLearningCharterEnums = async () => {
  return (await get({ url: `${BASE_URL}/learning-charter-enums` })).data;
};

export const searchReferenceIds = (input: string) => {
  return get({ url: `${BASE_URL}/references?searchTerm=${input.trim()}` });
};

export const getRequestSubscription = async ({ requestId, teamMemberId }: any) => {
  return (await get({ url: `${BASE_URL}/requests/${requestId}/subscription/${teamMemberId}` }))
    .data;
};

export const addRequestSubscription = async ({ requestId, teamMemberId }: any) => {
  return (await post({ url: `${BASE_URL}/requests/${requestId}/subscription/${teamMemberId}` }))
    .data;
};

export const deleteRequestSubscription = async ({ requestId, teamMemberId, data }: any) => {
  return (
    await del({ url: `${BASE_URL}/requests/${requestId}/subscription/${teamMemberId}`, data })
  ).data;
};

export const deleteRequest = async ({ requestId, data }: any) => {
  return (await del({ url: `${BASE_URL}/requests/${requestId}`, data })).data;
};

export const updateApproverChangeLogs = async data => {
  return (
    await post({
      url: `${getEnv('IT_BASE_URL')}/audit-log/approver-change`,
      data
    })
  ).data;
};

export const resubmitRequest = async (data: any) => {
  return (await put({ url: `${BASE_URL}/resubmit-ca/${data.requestId}`, data })).data;
};

export const resubmitLearningRequest = async (data: any) => {
  return (await put({ url: `${BASE_URL}/resubmit-al/${data.requestId}`, data })).data;
};

export const getResubmissionRights = async (data: any): Promise<Boolean> => {
  return (await get({ url: `${BASE_URL}/requests/${data.requestId}/user-has-resubmission-rights` }))
    .data;
};

export const getRequestTimeline = async ({
  requestId
}: {
  requestId: number;
}): Promise<RequestTimelineData> => {
  return (await get({ url: `${BASE_URL}/requests/${requestId}/requested-timeline` })).data;
};

export const updateRequestTimeline = async (data: UpdateRequestTimeline) => {
  return (await put({ url: `${BASE_URL}/requests/${data.requestId}/requested-timeline`, data }))
    .data;
};

export const getFDRenums = async (): Promise<BuildingData> => {
  return (await get({ url: `${BASE_URL}/Facilities-design-enums` })).data;
};

export const addFDRBuildingLocation = async (data: AddFDRBuildingPayload) => {
  return (await post({ url: `${BASE_URL}/requests/${data.requestId}/request-building`, data }))
    .data;
};

export const getFDRBuildingLocations = async ({
  requestId
}: {
  requestId: number;
}): Promise<GetBuilding[]> => {
  return (await get({ url: `${BASE_URL}/requests/${requestId}/request-buildings` })).data;
};

export const deleteFDRBuildingLocation = async ({
  requestId,
  fdDetailBuildingMappingId
}: {
  requestId: number;
  fdDetailBuildingMappingId: number;
}) => {
  const data = { requestId, fdDetailBuildingMappingId };
  return (await del({ url: `${BASE_URL}/requests/${requestId}/request-building`, data })).data;
};

export const updateFDRBuildingLocation = async (data: UpdateFDRBuildingPayload) => {
  return (await put({ url: `${BASE_URL}/requests/${data.requestId}/request-building`, data })).data;
};

export const resubmitFDRequest = async (data: any) => {
  return (await put({ url: `${BASE_URL}/resubmit-fd/${data.requestId}`, data })).data;
};

export const getFilterGroups = async () => {
  return (await get({ url: `${BASE_URL}/request-filter-groups` })).data;
};
