import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
// import fbt, { GenderConst, IntlVariations, IntlViewerContext, init } from 'fbt';
import { AppProvider } from './App.context';
import { useIdbHydrate } from 'utils/react-query.utils';
import { useFeature } from 'services/feature.service';
import MaintenancePage from 'pages/Maintenance/Maintenance.page';
import Loading from 'components/common/Loading/Loading';
import AuthorizedRoute from 'components/common/AuthorizedRoute/AuthorizedRoute';
import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import { useAuth0 } from '@auth0/auth0-react';
import AuthLoading from 'components/common/Loading/AuthLoading';
import { JwtToken, user } from 'services/auth.service';
import { useModifiedUserToken } from 'api/user';
import { UserContext } from 'context/User.context';

// const translations = { en_US: {} };

// init({ translations });

const config = {
  fallback: <Loading />
};
const HomeView = loadable(
  () => import(/* webpackPrefetch: true */ './pages/Home/Home.page'),
  config
);
const ErrorView = loadable(
  () => import(/* webpackPrefetch: true */ './pages/Error/Error.page'),
  config
);
const EditEnvView = loadable(
  () => import(/* webpackPrefetch: true */ './pages/EditEnv/EditEnv.page'),
  config
);
const SATAdmin = loadable(
  () => import(/* webpackPrefetch: true */ './pages/AdminArea/SATAdmin/SATAdmin.page'),
  config
);
const Requests = loadable(() => import(/* webpackPrefetch: true */ './pages/Requests'), config);
const Submissions = loadable(
  () => import(/* webpackPrefetch: true */ './pages/Submissions'),
  config
);
const Intake = loadable(() => import(/* webpackPrefetch: true */ './pages/Intake'), config);
const HelpCenter = loadable(
  () => import(/* webpackPrefetch: true */ './pages/HelpCenter/HelpCenter.page'),
  config
);
const TeamPlanner = loadable(
  () => import(/* webpackPrefetch: true */ './pages/TeamPlanner/TeamPlanner.page'),
  config
);
const Programs = loadable(() => import(/* webpackPrefetch: true */ './pages/Programs'), config);

const StaticSite = loadable(() => import(/* webpackPrefetch: true */ './pages/StaticSite'), config);

const AdminArea = loadable(() => import(/* webpackPrefetch: true */ './pages/AdminArea'), config);

// const RequestDetails = loadable(
//   () => import(/* webpackPrefetch: true */ './pages/Requests/RequestsDetails.page'),
//   config
// );

// const LOCALES = Object.freeze({
//   en_US: Object.freeze({
//     bcp47: 'en-US',
//     displayName: 'English (US)\u200e',
//     englishName: 'English (US)',
//     rtl: false
//   }),
//   en_TEST: Object.freeze({
//     bcp47: 'en-US',
//     displayName: 'English (US)\u200e',
//     englishName: 'English (US)',
//     rtl: false
//   }),
//   fb_HX: Object.freeze({
//     bcp47: 'fb-HX',
//     displayName: 'l33t 5p34k',
//     englishName: 'FB H4x0r',
//     rtl: false
//   }),
//   es_LA: Object.freeze({
//     bcp47: 'es-419',
//     displayName: 'Espa\u00F1ol',
//     englishName: 'Spanish',
//     rtl: false
//   }),
//   ar_AR: Object.freeze({
//     bcp47: 'ar',
//     displayName: '\u0627\u0644\u0639\u0631\u0628\u064A\u0629',
//     englishName: 'Arabic',
//     rtl: true
//   }),
//   he_IL: Object.freeze({
//     bcp47: 'he',
//     displayName: '\u05E2\u05D1\u05E8\u05D9\u05EA',
//     englishName: 'Hebrew',
//     rtl: true
//   }),
//   ja_JP: Object.freeze({
//     bcp47: 'ja',
//     displayName: '\u65E5\u672C\u8A9E',
//     englishName: 'Japanese',
//     rtl: false
//   })
// });

// const setLocal = locale => {
//   IntlViewerContext.locale = locale;
//   const html = document.getElementsByTagName('html')[0];
//   if (html != null) {
//     html.lang = LOCALES[locale].bcp47;
//   }
//   document.body.className = LOCALES[locale].rtl ? 'rtl' : 'ltr';
// };

const AppRouter = () => {
  // const [state, setState] = useState({
  //   locale: getEnv('DEV_MODE') ? 'en_TEST' : 'en_US',
  //   ex1Name: 'Someone',
  //   ex1Gender: IntlVariations.GENDER_UNKNOWN,
  //   ex1Count: 1,
  //   ex2Name: 'Someone',
  //   ex2Object: 'LINK',
  //   ex2Pronoun: GenderConst.UNKNOWN_SINGULAR
  // });

  const {
    user: auth0User,
    isAuthenticated,
    isLoading,
    error,
    loginWithRedirect,
    logout
  } = useAuth0();

  let connection = 'Applied';
  if (localStorage?.getItem('playwrightTest') === 'true') {
    connection = 'Test-Users';
  }

  const { setGeneralPermissions, settingUserData } = useContext(UserContext);
  const modifiedUserTokenQuery = useModifiedUserToken({
    args: { requestId: null, departmentId: null },
    enabled: !!JwtToken
  });

  useEffect(() => {
    if (modifiedUserTokenQuery?.data) {
      setGeneralPermissions(modifiedUserTokenQuery?.data);
    }
  }, [auth0User, isLoading, modifiedUserTokenQuery?.status]);

  useIdbHydrate();

  // useEffect(() => {
  // if (!state.locale === 'en_US') {
  //   try {
  //     import(`../../translations/${state.locale}.json`).then(({ default: payload }) => {
  //       for (const k in payload) {
  //         translations[k] = payload[k];
  //       }
  //       setLocal(state.locale);
  //     });
  //   } catch {
  //     console.log('cant find file');
  //   }
  // } else {
  // setLocal(state.locale);
  // }
  // }, [state.locale]);

  const { feature, underMaintenance } = useFeature('arc team');

  if (!isLoading) {
    if (!isAuthenticated) {
      let path = window.location.pathname + (window.location.search ?? '');
      loginWithRedirect({
        authorizationParams: {
          connection: connection
        },
        appState: { returnTo: path }
      });
    }
  }

  if (isLoading || settingUserData) {
    return <AuthLoading auth0Logout={logout} />;
  }

  return (
    <>
      {!feature && underMaintenance ? (
        <MaintenancePage />
      ) : (
        <ErrorBoundary>
          <AppProvider>
            <Switch>
              <AuthorizedRoute path="/" exact title="Request Portal" render={HomeView} />
              {/* <AuthorizedRoute path="/triage" title="Triage" render={() => <div>triage</div>} /> */}
              <AuthorizedRoute path="/programs" title="Programs" render={Programs} />
              <AuthorizedRoute path="/intake" title="Intake & Governance" render={Intake} />
              <AuthorizedRoute path="/requests" title="Requests" render={Requests} />
              <AuthorizedRoute path="/dashboard" title="Dashboard" render={Submissions} />
              <AuthorizedRoute
                path="/edit-env"
                title="Edit Environment"
                exact
                render={EditEnvView}
              />
              {/* <AuthorizedRoute path="/sat-admin/department" title="Admin" render={SATAdmin} /> */}
              <AuthorizedRoute path="/admin" title="Admin" render={AdminArea} />
              <AuthorizedRoute path="/help-center" title="Help Center" render={HelpCenter} exact />
              <AuthorizedRoute path="/team-planner" title="Team Planner" render={TeamPlanner} />
              <AuthorizedRoute path="/static-site" title="Static Site" render={StaticSite} />
              <Route
                path="/login"
                exact
                component={() => <Redirect to="/dashboard/explore/tasks" />}
              />
              {/* <Route path="/jwtlogin" exact render={() => <Login jwt />} /> */}
              <Route component={ErrorView} />
            </Switch>
          </AppProvider>
        </ErrorBoundary>
      )}
    </>
  );
};

// export default withRouter(AppRouter);
export default AppRouter;
