import { useQuery } from 'react-query';
import { TQuery } from 'api/api.types';
import { RequestOverviewAPI } from 'api';

export const useRequestOverview = ({ args, config }: TQuery = {}) => {
  return useQuery(['request-overview', args], () => RequestOverviewAPI.getRequestOverview(args), {
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: true,
    ...config
  });
};

export const useRequestOverviewEnums = ({ config }: TQuery = {}) => {
  return useQuery(['request-overview-enums'], () => RequestOverviewAPI.getRequestOverviewEnums(), {
    staleTime: 1000 * 60 * 5,
    ...config
  });
};

export const useGetRequestOverviewAddtionalDates = ({ args, config }: TQuery = {}) => {
  return useQuery(
    ['request-overview-additional-dates', args],
    () => RequestOverviewAPI.getOverviewAdditionalDates(args),
    {
      staleTime: 1000 * 60 * 5,
      ...config
    }
  );
};

export const useGetRequestOverviewSingleAddtionalDate = ({ args, config }: TQuery = {}) => {
  return useQuery(
    ['request-overview-single-additional-date', args],
    () => RequestOverviewAPI.getOverviewSingleAdditionalDate(args),
    {
      staleTime: 1000 * 60 * 5,
      ...config
    }
  );
};

export const useGetRequestRelated = ({ args, config }: TQuery = {}) => {
  return useQuery('request-related', () => RequestOverviewAPI.getRequestRelated(args), {
    staleTime: 1000 * 60 * 5,
    cacheTime: 0,
    ...config
  });
};
