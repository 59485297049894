import React, { useContext, memo, useState } from 'react';
import {
  Hidden,
  AppBar,
  Toolbar,
  IconButton,
  // Avatar,
  Typography,
  Badge,
  Link,
  MenuItem,
  Menu,
  Button
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { AppLayoutContext } from '../AppLayout.context';
import { user } from 'services/auth.service';
import { useStyles } from './AppToolbar.styles';
import { AVATAR_URL } from '../AppLayout.constants';
import { CONTACT_URL } from 'api/user/user.constants';
import {
  isDevEnv,
  isProdEnv,
  isProdTestEnv,
  isStgEnv,
  isPoc1ENV,
  isPoc2ENV
} from 'services/apiEnv.service';
import Avatar from 'components/common/Avatar/Avatar';
import LogoIcon from 'components/icons/Logo.icon';
import { useAuth0 } from '@auth0/auth0-react';
import ChevronIcon from 'components/icons/Chevron.icon';
import ChevronBoldIcon from '../../icons/ChevronBold.icon';
import { ChevronNewIcon, LogoutIcon } from 'components/icons';

const DefaultMobileToolbar: React.FC = () => {
  const classes = useStyles();
  const { handleMobileDrawerToggle } = useContext(AppLayoutContext);

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton edge="start" onClick={handleMobileDrawerToggle} className={classes.iconButton}>
          <MenuIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

const DefaultDesktopToolbar: React.FC = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { title, handleLogoClick } = useContext(AppLayoutContext);
  const { logout } = useAuth0();

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar
        className={
          isProdEnv
            ? classes.toolbar
            : isProdTestEnv
            ? classes.playgroundToolbar
            : isStgEnv
            ? classes.qaToolbar
            : isDevEnv
            ? classes.devToolbar
            : isPoc1ENV
            ? classes.pocOneToolbar
            : isPoc2ENV
            ? classes.pocTwoToolbar
            : classes.toolbar
        }
      >
        <IconButton edge="start" onClick={handleLogoClick} className={classes.iconButton}>
          <LogoIcon />
        </IconButton>

        <Typography
          color="inherit"
          noWrap
          className={!isProdEnv ? classes.playgroundTitle : classes.title}
        >
          {isProdEnv && 'Applied Request Center'}
          {isStgEnv && 'Applied Request Center - QA'}
          {isProdTestEnv && 'Applied Request Center - PLAYGROUND'}
          {isDevEnv && 'Applied Request Center - DEV'}
          {isPoc1ENV && 'Applied Request Center - POC 1'}
          {isPoc2ENV && 'Applied Request Center - POC 2'}
          {!isProdEnv &&
            !isStgEnv &&
            !isProdTestEnv &&
            !isDevEnv &&
            !isPoc1ENV &&
            !isPoc2ENV &&
            'Applied Request Center'}
        </Typography>

        <IconButton
          edge="end"
          color="inherit"
          onClick={(e: any) => {
            e.stopPropagation();
            setAnchorEl(e?.currentTarget);
          }}
          data-cy="username-apptoolbar"
          style={{ backgroundColor: 'transparent', textAlign: 'center' }}
          disableRipple
        >
          <Typography style={{ fontWeight: 600, fontSize: '14px' }}>
            {user?.FullName ?? ''}
          </Typography>
          <ChevronNewIcon
            fill="#fff"
            style={{ height: '10px', width: '10px', marginLeft: '8px' }} //marginLeft:'1px',marginTop:'3px'
          />
        </IconButton>
        <Menu
          id={'avatar-menu'}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          getContentAnchorEl={null}
        >
          <MenuItem
            style={{ width: '128px' }}
            onClick={() =>
              logout({ logoutParams: { returnTo: window.location.origin, federated: true } })
            }
          >
            <LogoutIcon style={{ height: '14px', width: '14px', marginRight: '8px' }} />
            Log Out
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

const AppToolbar: React.FC = memo(() => (
  <>
    <Hidden smUp>
      <DefaultMobileToolbar />
    </Hidden>

    <Hidden xsDown>
      <DefaultDesktopToolbar />
    </Hidden>
  </>
));

export default AppToolbar;
