import { useQuery, useInfiniteQuery, useQueryClient } from 'react-query';
import { RequestAPI } from 'api';
import { TQuery, TInfiniteQuery } from 'api/api.types';
import { mapToOptions, reduceToOptions } from 'api/api.utils';
import { IFilesData, IPath } from 'components/FileTypes/Files.types';

export const useRequest = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['request', args], () => RequestAPI.getRequest(args), {
    staleTime: 1000 * 60 * 5,
    ...config,
    refetchOnWindowFocus: true,
    enabled: !!args?.id
  });
};

export const useRequests = ({ args, ...config }: TInfiniteQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async ({ pageParam = 1 }) => {
    const { items, ...headers } = await RequestAPI.getRequests(args, pageParam);

    queryClient.setQueryData(['requests-headers', { id: args?.id }], headers);

    return items;
  };

  return useInfiniteQuery(['requests', args], queryFn, {
    staleTime: 1000 * 60 * 5,
    getNextPageParam: (_, allPages) => allPages.length + 1,
    keepPreviousData: true,
    refetchInterval: 1000 * 60 * 5,
    cacheTime: 0,
    ...config
  });
};

export const useCSVRequests = ({ args, ...config }: TInfiniteQuery = {}) => {
  const queryClient = useQueryClient();

  const queryFn = async () => {
    const { items, ...headers } = await RequestAPI.getCSVRequests(args);

    queryClient.setQueryData(['requests-csv-headers'], headers);

    return items;
  };

  return useQuery(['requests-csv', args], queryFn, {
    staleTime: Infinity,
    enabled: false,
    cacheTime: 0,
    ...config
  });
};

export const useRequestFilterEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestAPI.getRequestFilterEnums());

  return useQuery(['request-filter-enums'], queryFn, { staleTime: Infinity, ...config });
};

export const useRequestViewColumns = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['requests-view-columns', args], () => RequestAPI.getRequestViewColumns(args), {
    staleTime: 1000 * 60 * 5,
    refetchInterval: 1000 * 60 * 5,
    ...config
  });
};

export const useBrandingDetails = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['request-branding-details', args], () => RequestAPI.getBrandingDetails(args), {
    staleTime: 1000 * 60,
    ...config
  });
};

export const useGenericEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestAPI.getGenericEnums());

  return useQuery(['generic-enums'], queryFn, { staleTime: Infinity, ...config });
};

export const useFDREnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestAPI.getFDRenums());

  return useQuery(['fdr-enums'], queryFn, { staleTime: Infinity, ...config });
};

export const useBrandingEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestAPI.getBrandingEnums());

  return useQuery(['branding-enums'], queryFn, { staleTime: Infinity, ...config });
};

export const useActiveDepartments = (config: TQuery = {}) => {
  const queryFn = async () => mapToOptions(await RequestAPI.getActiveRequestDepartments());
  return useQuery(['active-departments'], queryFn);
};

export const useBrandingAudienceTypes = (config: TQuery = {}) => {
  const queryFn = async () => mapToOptions(await RequestAPI.getBrandingAudienceTypes());

  return useQuery(['request-audience-types'], queryFn, { staleTime: Infinity, ...config });
};

export const useMultimediaDeliverables = (config: TQuery = {}) => {
  const queryFn = async () => mapToOptions(await RequestAPI.getMultimediaDeliverables());

  return useQuery(['multimedia-deliverables'], queryFn, { staleTime: Infinity, ...config });
};

export const useProjectPurposes = ({ args, ...config }: TQuery) => {
  const queryFn = async () => await RequestAPI.getProjectPurposes(args);

  return useQuery(['project-purposes', args], queryFn, {
    staleTime: Infinity,
    enabled: !!args?.id,
    ...config
  });
};

export const useBrandingShippingCriteria = (blacklistedIds: any = [], config = {}) => {
  const queryFn = async () => {
    return (await RequestAPI.getBrandingShippingCriteria())
      .filter((el: any) => !blacklistedIds.includes(el.id))
      .map((el: any) => ({ value: el.id, name: el.name, label: el.displayValue }));
  };

  return useQuery(['branding-shipping-criteria', blacklistedIds], queryFn, {
    staleTime: Infinity,
    ...config
  });
};

export const useBrandingPrintRequestEnums = (config: TQuery = {}) => {
  const queryFn = async () => {
    return reduceToOptions(await RequestAPI.getBrandingPrintRequestEnums());
  };

  return useQuery('branding-print-enums', queryFn, { staleTime: Infinity, ...config });
};

export const useSignagePrintEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestAPI.getSignagePrintEnums());

  return useQuery('signage-print-enums', queryFn, { staleTime: Infinity, ...config });
};

export const useClinicalEvalEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestAPI.getClinicalEvalEnums());

  return useQuery('clinical-eval-enums', queryFn, { staleTime: Infinity, ...config });
};

export const useBrandingDeliveryBuildings = (config: TQuery = {}) => {
  const queryFn = async () => {
    const data = await RequestAPI.getBrandingDeliveryBuildings();

    const dict = data.reduce((acc: any, el: any) => {
      const campus = acc[el.campusX.campusName];

      acc[el.campusX.campusName] = !campus ? [el] : [...campus, el];

      return acc;
    }, {});

    const nestedOptions = Object.entries(dict).map(([key, value]: any) => ({
      label: key,
      options: value.map((el: any) => ({
        value: el.buildingId,
        label: el.buildingName,
        chipLabel: `${el.campusX.campusName} - ${el.buildingName}`
      }))
    }));

    return nestedOptions;
  };

  return useQuery(['mapwize-buidlings'], queryFn, { staleTime: Infinity, ...config });
};

export const useMapwizeBuildingRooms = ({ args, ...config }: TQuery = {}) => {
  const queryFn = async () => {
    return (await RequestAPI.getMapsizeBuildingRooms(args)).map((el: any) => ({
      value: el.roomId,
      label: el.roomTitle ? `${el.roomTitle} (#${el.roomId})` : `Room Id: ${el.roomId}`
    }));
  };

  return useQuery(['mapwize-buidling-rooms', args], queryFn, {
    staleTime: Infinity,
    enabled: !!args?.id,
    ...config
  });
};

export const useAttachmentExtensions = (config: TQuery = {}) => {
  const queryFn = async () => {
    return (await RequestAPI.getAttachmentExtensions()).map((el: any) => el.name);
  };

  return useQuery('attachment-extensions', queryFn, { staleTime: Infinity, ...config });
};

export const useFacilityFormTypes = (config: TQuery = {}) => {
  const queryFn = async () => mapToOptions(await RequestAPI.getFacilityFormTypes());

  return useQuery(['facility-form-types'], queryFn, { staleTime: Infinity, ...config });
};

export const useFacilityJustifications = (config: TQuery = {}) => {
  const queryFn = async () => mapToOptions(await RequestAPI.getFacilityJustifications());

  return useQuery(['facility-justifications'], queryFn, { staleTime: Infinity, ...config });
};

export const useFacilityServiceRequirements = (config: TQuery = {}) => {
  const queryFn = async () => {
    return mapToOptions(await RequestAPI.getFacilityServiceRequirements());
  };

  return useQuery(['facility-service-requirements'], queryFn, { staleTime: Infinity, ...config });
};

export const useATSFormTypes = (config: TQuery = {}) => {
  const queryFn = async () => mapToOptions(await RequestAPI.getATSFormTypes());

  return useQuery(['ats-form-types'], queryFn, { staleTime: Infinity, ...config });
};

export const useDocumentationTypes = (config: TQuery = {}) => {
  const queryFn = async () => mapToOptions(await RequestAPI.getDocumentationTypes());

  return useQuery(['ats-documentation-types'], queryFn, { staleTime: Infinity, ...config });
};

export const useBusinessImpactTypes = (config: TQuery = {}) => {
  const queryFn = async () => {
    // return mapToOptions(await RequestAPI.getBusinessImpactEnums());
    return (await RequestAPI.getBusinessImpactEnums()).map((el: any) => ({
      value: el.id,
      name: el.name,
      label: el.description,
      description: el.description,
      ...el
    }));
  };

  return useQuery(['ats-business-impact-types'], queryFn, { staleTime: Infinity, ...config });
};

export const useATSRequestedCompletionTimes = (config: TQuery = {}) => {
  const queryFn = async () => mapToOptions(await RequestAPI.getATSRequestedCompletionTimes());

  return useQuery(['ats-requested-completion-times'], queryFn, { staleTime: Infinity, ...config });
};

export const useScopeNoteTypes = (config: TQuery = {}) => {
  // const queryFn = async () => mapToOptions(await RequestAPI.getScopeNoteTypes());
  const queryFn = async () =>
    (await RequestAPI.getScopeNoteTypes()).reduce(
      (acc: any, el: any) => ({ ...acc, [el.id]: el }),
      {}
    );

  return useQuery(['ats-requested-completion-times'], queryFn, { staleTime: Infinity, ...config });
};

// export const useJustificationNotes = ({ args, ...config }: any) => {
//   return useQuery(['justification-notes', args], () => RequestAPI.getJustificationNotes(args), {
//     staleTime: 1000 * 60,
//     ...config
//   });
// };

export const useJustificationNotes = ({ args, ...config }: any) => {
  return useQuery(['justification-notes', args], () => RequestAPI.getJustificationNotes(args), {
    staleTime: 1000 * 60,
    enabled: !!args.requestId,
    ...config
  });
};

export const useRequestDetailsInfo = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['request-info', args], () => RequestAPI.getRequestDetailsInfo(args), {
    staleTime: 1000 * 60,
    ...config
  });
};

export const useCharter = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['charter', args], () => RequestAPI.getCharterDetails(args), {
    staleTime: 1000 * 60,
    ...config
  });
};

export const useLearningCharter = ({ args, ...config }: TQuery = {}) => {
  return useQuery(['learning-charter', args], () => RequestAPI.getLearningCharterDetails(args), {
    staleTime: 1000 * 60,
    ...config
  });
};

export const useCharterEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestAPI.getCharterEnums());

  return useQuery(['charter-enums'], queryFn, { staleTime: Infinity, ...config });
};

export const useLearningCharterEnums = (config: TQuery = {}) => {
  const queryFn = async () => reduceToOptions(await RequestAPI.getLearningCharterEnums());

  return useQuery(['learning-charter-enums'], queryFn, { staleTime: Infinity, ...config });
};

export const useRequestSubscription = ({ args, ...config }: any) => {
  return useQuery(['request-subscription', args], () => RequestAPI.getRequestSubscription(args), {
    staleTime: 1000 * 60,
    ...config,
    refetchOnWindowFocus: true
  });
};

export const useGetResubmissionRights = ({ args, ...config }: any) => {
  return useQuery('submission-rights', () => RequestAPI.getResubmissionRights(args), {
    staleTime: 1000 * 60,
    enabled: !!args?.requestId,
    ...config
  });
};

export const useGetRequestTimeline = ({ args, ...config }) => {
  return useQuery(['request-timeline', args], () => RequestAPI.getRequestTimeline(args), {
    staleTime: 1000 * 60,
    enabled: !!args?.requestId,
    ...config
  });
};

export const useGetFDRBuildingLocations = ({ args, ...config }) => {
  return useQuery(
    ['fdr-building-locations', args],
    () => RequestAPI.getFDRBuildingLocations(args),
    {
      staleTime: 1000 * 60,
      enabled: !!args?.requestId,
      ...config
    }
  );
};

export const useGetFilterGroups = () => {
  return useQuery(['filter-groups'], () => RequestAPI.getFilterGroups(), { staleTime: Infinity });
};
