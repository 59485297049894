import { useMutation, UseMutationOptions } from 'react-query';
import { RequestOverviewAPI } from 'api';

export const useUpdateRequestOverview = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.updateRequestOverview, { ...config });
};

export const useAddOverviewRegion = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.addOverviewRegion, {
    ...config
  });
};

export const useRemoveOverviewRegion = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.removeOverviewRegion, {
    ...config
  });
};

export const useAddOverviewReference = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.addOverviewReference, {
    ...config
  });
};

export const useRemoveOverviewReference = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.removeOverviewReference, {
    ...config
  });
};

export const useEditOverviewReference = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.editOverviewReference, {
    ...config
  });
};

export const useAddOverviewDependency = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.addOverviewDependency, {
    ...config
  });
};

export const useRemoveOverviewDependency = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.removeOverviewDependency, {
    ...config
  });
};

export const useEditOverviewDependency = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.editOverviewDependency, {
    ...config
  });
};

export const useAddOverviewAdditionalDate = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.addOverviewAdditionalDate, {
    ...config
  });
};

export const useRemoveOverviewAdditionalDate = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.removeOverviewAdditionalDate, {
    ...config
  });
};

export const useEditOverviewAdditionalDate = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.editOverviewAdditionalDate, {
    ...config
  });
};

export const useAddRequestRelated = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.addRequestRelated, {
    ...config
  });
};

export const useDeleteRequestRelated = (config?: UseMutationOptions) => {
  return useMutation(RequestOverviewAPI.deleteRequestRelated, {
    ...config
  });
};
