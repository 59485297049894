import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { pathOr } from 'ramda';
import UserAPI, { PERMISSIONTYPES } from 'api/user';
import loadable from '@loadable/component';
import { useFeature, useWizardFeature } from 'services/feature.service';
import { useAuth0 } from '@auth0/auth0-react';
import AppLayout from 'components/AppLayout/AppLayout';
import { UserContext } from 'context/User.context';
import { useQueryClient } from 'react-query';
import Loading from '../Loading/Loading';

type TAuthorizedRoute = {
  title?: string;
  render: React.ComponentType<any>;
  path: string;
  exact?: boolean;
  tokenQuery?: any;
};

const ROUTEPATHENV = 'appliedarc-saved-route';

const setRoute = route => {
  // console.log("setting route to: ", route);
  sessionStorage.setItem(ROUTEPATHENV, route);
};

export const getRedirectRoute = () => {
  const path = sessionStorage.getItem(ROUTEPATHENV);
  return path;
};

export const getAuthRefreshRedirect = () => {
  const path = sessionStorage.getItem('auth-refresh-redirect');
  return path;
};

export const clearAuthRedirect = () => {
  sessionStorage.removeItem('auth-refresh-redirect');
};

const AuthorizedRoute: React.FC<TAuthorizedRoute> = ({ path, title, render: Page, ...rest }) => {
  const { user: auth0User, isAuthenticated: isAuthenticatedAuth0, loginWithRedirect } = useAuth0();

  const meQuery = UserAPI.useMe();
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData(['modified-user-token-data']);

  const userContext = useContext(UserContext);

  const userPMAdminOrAdmin = userContext?.generalPermissions?.permissions?.includes(
    PERMISSIONTYPES.ViewIntakePage
  );

  const currentPath = pathOr('/', ['location', 'pathname'], rest);
  const search = pathOr('', ['location', 'search'], rest);

  const ErrorView = loadable(() =>
    import(/* webpackPrefetch: true */ '../../../pages/Error/Error.page')
  );

  React.useEffect(() => {
    setRoute(pathOr('/', ['location', 'pathname'], rest) + search);
  }, [currentPath, isAuthenticatedAuth0, auth0User, search, userPMAdminOrAdmin]);

  const { feature } = useFeature('arc submission wizard');
  const wizardFeature = useWizardFeature();

  // const AuthenticatedPage = withAuthenticationRequired(Page, {
  //   onRedirecting: () => {
  //     console.log('REDIRECTING - displaying Loading component');
  //     return <Loading />;
  //   }
  // });

  const isStaticSite = title?.includes('Static Site');

  return (
    <>
      {isAuthenticatedAuth0 ? (
        <Route
          path={path}
          {...rest}
          render={props => {
            if (
              props.location.pathname === '/requests/explore/me' ||
              props.location.pathname === '/login'
            ) {
              return <Redirect to="/dashboard/explore/tasks" />;
            }
            if (props.location.pathname === '/sat-admin/department') {
              return <Redirect to="/admin" />;
            }

            if (props.location.pathname === '/') {
              // Retrieve, use, and delete url stored before token refresh
              const redirectRoute = getAuthRefreshRedirect();
              const redirectTo = redirectRoute ? redirectRoute : '/dashboard/explore/tasks';
              clearAuthRedirect();
              return <Redirect to={redirectTo} />;
            }

            // NOTE: DISABLE WIZARD WHEN MAINTENANCE IS REQUIRED
            if (props.location.pathname === '/dashboard/create' && !feature && wizardFeature) {
              return <ErrorView />;
            }

            // NOTE: Prevent Error view from showing when permissions query is still loading
            if (props.location.pathname.includes('/intake') && queryData === undefined) {
              return <Loading />;
            }

            if (props.location.pathname.includes('/intake') && !userPMAdminOrAdmin && !!queryData) {
              return <ErrorView />;
            }

            return (
              <AppLayout
                {...props}
                title={title}
                user={userContext}
                meQuery={meQuery}
                isStaticSite={isStaticSite}
              >
                {(props: any) => <Page {...props} />}
              </AppLayout>
            );
          }}
        />
      ) : (
        loginWithRedirect()
      )}
    </>
  );
};

export default AuthorizedRoute;
