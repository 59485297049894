import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Tabs, Tab } from './AppDrawer.styles';
import { NAV_LINKS } from '../AppLayout.constants';
import { DeleteSweep as DeleteSweepIcon } from '@material-ui/icons';
import idb from 'services/idb.service';
import { useAppContext } from 'App.context';
import { isProdEnv } from 'services/apiEnv.service';
// import { UserAPI } from 'api';
// import { isAuthenticated } from 'services/auth.service';
// import { APPROVED_MEMBERS } from 'api/team-planner';
import { serveMaintenancePageConstants, user } from 'services/auth.service';
import { UserContext } from 'context/User.context';
import { PERMISSIONTYPES } from 'api/user';
import { useQueryClient } from 'react-query';
import { useFeature, useGetFeatureToggle } from 'services/feature.service';
import { get } from 'lodash';

interface INavLinks {
  isOpen: boolean;
  navTabIdx: number;
  handleChange: () => void;
  handleDrawerToggle?: () => void;
  hasAdminRole?: boolean;
  userPMAdminOrAdmin?: boolean;
  adminPermission?: boolean;
}

const UtilityButtons = ({ isOpen, theme }) => {
  const { setIsClientStorageReload } = useAppContext();

  return (
    <div>
      <Tab
        icon={
          <div style={{ display: 'flex', margin: 0 }}>
            <DeleteSweepIcon style={{ width: 20, height: 20 }} />
          </div>
        }
        label={
          <div style={{ textTransform: 'none', marginLeft: theme.spacing(2) }}>
            {isOpen && 'Clear Client Storage'}
          </div>
        }
        onClick={() => {
          setIsClientStorageReload(true);
          localStorage.clear();
          idb.clear().then(() => {
            window.location.reload();
          });
        }}
      />
    </div>
  );
};

const NavLinks: React.FC<INavLinks> = ({ isOpen, navTabIdx, handleChange, handleDrawerToggle }) => {
  const theme = useTheme();
  const userContext = useContext(UserContext);
  const [organizationPermission, setOrganizationPermission] = useState(false);
  const [userPMAdminOrAdmin, setUserPMAdminOrAdmin] = useState(false);
  const [teamPlannerAccess, setTeamPlannerAccess] = useState(false);
  const [taskTemplatesAccess, setTaskTemplatesAccess] = useState(false);

  useEffect(() => {
    if (userContext?.generalPermissions?.permissions) {
      setTaskTemplatesAccess(
        userContext?.generalPermissions?.permissions?.includes(PERMISSIONTYPES.EditTaskTemplates)
      );
      setOrganizationPermission(
        userContext?.generalPermissions?.permissions?.includes(PERMISSIONTYPES.OrganizationEdit)
      );
      setUserPMAdminOrAdmin(
        userContext?.generalPermissions?.permissions?.includes(PERMISSIONTYPES.ViewIntakePage)
      );
      setTeamPlannerAccess(
        userContext?.generalPermissions?.permissions?.includes(PERMISSIONTYPES.TeamPlannerAccesss)
      );
    }
  }, [userContext?.generalPermissions]);

  return (
    <div
      style={{
        height: '100vh',
        paddingTop: 52,
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Tabs
        orientation="vertical"
        indicatorColor="primary"
        value={navTabIdx}
        onChange={handleChange}
      >
        {NAV_LINKS.map(el => (
          <Tab
            key={el.displayName}
            data-cy={`app-drawer-${el.name}`}
            icon={
              <div
                style={{
                  display: 'flex',
                  margin: 0
                }}
              >
                {el.icon}
              </div>
            }
            label={
              <div style={{ textTransform: 'none', marginLeft: theme.spacing(2) }}>
                {isOpen && el.displayName}
              </div>
            }
            onClick={() => {
              el.onClick?.();
              handleDrawerToggle?.();
            }}
            style={{
              display:
                el.name === 'intake-governance'
                  ? userPMAdminOrAdmin && user?.department !== 'Branding'
                    ? 'flex'
                    : 'none'
                  : el.displayName === 'Admin'
                  ? organizationPermission || taskTemplatesAccess
                    ? 'flex'
                    : 'none'
                  : el.name === 'team-planner'
                  ? teamPlannerAccess
                    ? 'flex'
                    : 'none'
                  : 'flex'
            }}
          />
        ))}
      </Tabs>

      {!isProdEnv && <UtilityButtons isOpen={isOpen} theme={theme} />}
    </div>
  );
};

export default NavLinks;
